
import { FaUserCheck } from "@react-icons/all-files/fa/FaUserCheck" ;
import { FaBell } from "@react-icons/all-files/fa/FaBell" ;
import { FaBookOpen } from "@react-icons/all-files/fa/FaBookOpen" ;
import { FaFileSignature } from "@react-icons/all-files/fa/FaFileSignature" ;
import { FaHourglassHalf } from "@react-icons/all-files/fa/FaHourglassHalf" ;
import { FaLock } from "@react-icons/all-files/fa/FaLock" ;
import { RiDraftFill } from "@react-icons/all-files/ri/RiDraftFill" ;
import { FaRegListAlt } from "@react-icons/all-files/fa/FaRegListAlt" ;
import { FaUsers } from "@react-icons/all-files/fa/FaUsers" ;
import { MdNoEncryption } from "@react-icons/all-files/md/MdNoEncryption" ;
import { FaUserTimes } from "@react-icons/all-files/fa/FaUserTimes" ;
import { FaBellSlash } from "@react-icons/all-files/fa/FaBellSlash" ;
import { FaPlusCircle } from "@react-icons/all-files/fa/FaPlusCircle" ;
import { FaMinusCircle } from "@react-icons/all-files/fa/FaMinusCircle" ;
import { FaChevronCircleRight } from "@react-icons/all-files/fa/FaChevronCircleRight" ;
import { FaChevronCircleDown } from "@react-icons/all-files/fa/FaChevronCircleDown" ;
import { FaChevronCircleUp } from "@react-icons/all-files/fa/FaChevronCircleUp" ;
import { FaFolder } from "@react-icons/all-files/fa/FaFolder" ;
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight" ;
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle" ;
import { FaInfoCircle } from "@react-icons/all-files/fa/FaInfoCircle" ;
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft" ;
import { FaQuestionCircle } from "@react-icons/all-files/fa/FaQuestionCircle" ;
import { BsSearch } from "@react-icons/all-files/bs/BsSearch" ;
import { BiSend } from "@react-icons/all-files/bi/BiSend" ;
import { HiEyeOff } from "@react-icons/all-files/hi/HiEyeOff" ;
import { HiEye } from "@react-icons/all-files/hi/HiEye" ;
import { HiCheckCircle } from "@react-icons/all-files/hi/HiCheckCircle" ;
import { HiOutlineMenu } from "@react-icons/all-files/hi/HiOutlineMenu" ;
import { HiOutlineDocumentDownload } from "@react-icons/all-files/hi/HiOutlineDocumentDownload" ;
import { RiDeleteBin2Fill } from "@react-icons/all-files/ri/RiDeleteBin2Fill" ;
import { BiLogOutCircle } from "@react-icons/all-files/bi/BiLogOutCircle" ;
import { RiUserUnfollowFill } from "@react-icons/all-files/ri/RiUserUnfollowFill" ;
import { FaCheck } from "@react-icons/all-files/fa/FaCheck" ;
import { FaTimes } from "@react-icons/all-files/fa/FaTimes" ;
import { FaChartLine } from "@react-icons/all-files/fa/FaChartLine" ;
import { RiArchiveFill } from "@react-icons/all-files/ri/RiArchiveFill" ;
import { FaBuilding } from "@react-icons/all-files/fa/FaBuilding" ;
import { HiTemplate } from "@react-icons/all-files/hi/HiTemplate";
import { ImInsertTemplate } from "@react-icons/all-files/im/ImInsertTemplate";

export const G1Icon = {
    ArchivedDocuments:      RiArchiveFill,
    Back:                   FaChevronLeft,
    CanceledDocuments:      FaUserTimes,
    Check:                  FaCheckCircle,
    Contacts:               FaUsers,
    Corporations:           FaBuilding,
    CreateTemplate:         ImInsertTemplate,
    Delete:                 RiDeleteBin2Fill,
    DesactivateUser:        RiUserUnfollowFill,
    Down:                   FaChevronCircleDown,
    Download:               HiOutlineDocumentDownload,
    Draft:                  RiDraftFill,
    Edit:                   RiDraftFill,
    ExpiredDocuments:       FaBellSlash,
    Folder:                 FaFolder,
    GoodPwdConstraint:      FaCheck,
    Hide:                   HiEyeOff,
    Info:                   FaInfoCircle,
    LegalInformations:      FaRegListAlt,
    Lock:                   FaLock,
    Logout:                 BiLogOutCircle,
    MenuBurger:             HiOutlineMenu,
    Minus:                  FaMinusCircle,
    NavigationSeparator:    FaChevronRight,
    NewSignature:           FaFileSignature,
    Notifications:          FaBell,
    Plus:                   FaPlusCircle,
    Question:               FaQuestionCircle,
    RefusedDocuments:       MdNoEncryption,
    Right:                  FaChevronCircleRight,
    Search:                 BsSearch,
    Send:                   BiSend,
    Signatures:             FaBookOpen,
    SignedDocuments:        FaLock,
    SmallCheck:             HiCheckCircle,
    Statistics:             FaChartLine,
    Templates:              HiTemplate,
    ToBeApprovedDocuments:  FaUserCheck,
    ToBeSignedDocuments:    FaHourglassHalf,
    Up:                     FaChevronCircleUp,
    View:                   HiEye,
    WrongPwdConstraint:     FaTimes,
} ;

import { FaDropbox } from "@react-icons/all-files/fa/FaDropbox"
import { FaGoogle } from "@react-icons/all-files/fa/FaGoogle"
import { FaGoogleDrive } from "@react-icons/all-files/fa/FaGoogleDrive"
import { FaMicrosoft } from "@react-icons/all-files/fa/FaMicrosoft"
import { SiMicrosoftonedrive } from "@react-icons/all-files/si/SiMicrosoftonedrive"
import { RiFolderUnknowFill } from "@react-icons/all-files/ri/RiFolderUnknowFill"
import { GenIcon, IconType } from "@react-icons/all-files"

export const ChamberSignIconLogo:IconType = (props) => {
    return GenIcon({
        tag:"svg",
        attr:{ viewBox:"0 0 1024 512" },
        child:[
            {
                tag:"path",
                attr: { 
                    d:"M 787.551 500.5 C 787.551 345.132 664.184 219.168 511.998 219.168 C 359.815 219.168 236.449 345.132 236.449 500.5 L 33.025 500.5 C 33.025 230.42 247.465 11.5 511.998 11.5 C 776.534 11.5 990.976 230.42 990.976 500.5 L 787.551 500.5 Z"
                }
            } as any // in order not to have the error about child array missing
        ]
    })(props);
} ;

export const AuthIcon = {
    Google:                 FaGoogle,
    Microsoft:              FaMicrosoft,
    Chambersign:            ChamberSignIconLogo,
    Unknown:                FaQuestionCircle,
} ;

export const CloudIcon = {
    DropBox:                FaDropbox,
    GoogleDrive:            FaGoogleDrive,
    OneDrive:               SiMicrosoftonedrive,
    Unknown:                RiFolderUnknowFill
} ;

